import Form from "Atoms/Form";

const OptionSelect = ({
  facetOptions,
  selectedFacetId,
  setSelectedFacetId,
}) => {
  let options = [{ value: null, label: "", disabled: true }];

  if (facetOptions)
    options = options.concat(
      facetOptions.map((facetOption) => {
        return { value: facetOption.id, label: facetOption.name };
      })
    );

  console.log("options", options);
  return (
    <Form.Select
      options={options}
      label={""}
      size={1.5}
      value={selectedFacetId}
      // value={selectedValue}
      // borderIntent={
      //   !isIndexOptionErrorState ||
      //   (selectedValue != null && selectedValue !== "")
      //     ? false
      //     : "negative"
      // }
      onChange={(e) => setSelectedFacetId(e.target.value)}
    />
  );
};

export default OptionSelect;
