const createCheckFitmentRequest = (customerVehicles) => {
  const checkFitmentRequest = customerVehicles?.map((customerVehicle) => {
    const request = {};
    const engineIds = {};

    request.requestIdentifier = customerVehicle.projectId;

    if (customerVehicle.vehicleBaseId)
      request.vehicleBaseId = customerVehicle.vehicleBaseId;
    if (customerVehicle.raceTypeId)
      request.raceTypeId = customerVehicle.raceTypeId;
    if (customerVehicle.engineManufacturerId)
      engineIds.manufacturerId = customerVehicle.engineManufacturerId;
    if (customerVehicle.engineMakeId)
      engineIds.makeId = customerVehicle.engineMakeId;
    if (customerVehicle.engineSeriesId)
      engineIds.seriesId = customerVehicle.engineSeriesId;
    if (customerVehicle.engineConfigurationId)
      engineIds.configurationId = customerVehicle.engineConfigurationId;
    if (customerVehicle.engineQualifierId)
      engineIds.qualifierId = customerVehicle.engineQualifierId;
    if (customerVehicle.engineVersionId)
      engineIds.versionId = customerVehicle.engineVersionId;
    if (customerVehicle.engineDisplacementId)
      engineIds.displacementId = customerVehicle.engineDisplacementId;
    if (customerVehicle.engineCodeId)
      engineIds.codeId = customerVehicle.engineCodeId;
    if (customerVehicle.engineCamShaftTypeId)
      engineIds.camshaftTypeId = customerVehicle.engineCamShaftTypeId;
    if (customerVehicle.engineFuelSystemId)
      engineIds.fuelSystemId = customerVehicle.engineFuelSystemId;

    if (Object.keys(engineIds).length) request.engineIds = engineIds;

    return request;
  });
  return checkFitmentRequest;
};

export { createCheckFitmentRequest };
