"use client"

import { createContext, useEffect, useState, useContext, useCallback } from "react";
import useSegment from "Hooks/useSegment";
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { NavigationContext } from "Clutch/Contexts/Navigation/NavigationContext";
import { TrackingContext } from "Contexts/TrackingContext/TrackingContext";
import { RecommendationProvider } from "Clutch/Stores/RecommendationStore/RecommendationStore"
import useRecentHistory from 'Clutch/Hooks/useRecentHistory'
import useAcquisitionType from "Clutch/Hooks/useAcquisition";
const ProductPageContext = createContext({});

const ProductPageProvider = ({ children, value }) => {

  //having multiple local state objects like this solves race conditions
  const [context, setContext] = useState({
    ...value,
    isFitmentDrawerOpen: false,
    isAdditionalFacetDrawerOpen: false,
    isGarageSaleDrawerOpen: false, 
    isCompareToolPresent: true
  });


  const [purchasedOrderContext, setPurchasedOrderContext] = useState({});
  const [fitmentContext, setFitmentContext] = useState({});
  const [shippingContext, setShippingContext] = useState({});
  const [shippingEstimateContext, setShippingEstimateContext] = useState({});
  const [clientContext, setClientContext] = useState({
    quantityRequested: 1,
    isIndexOptionErrorState: false,
    isDetailsAccordionOpen: true,
    isCarbAccordionOpen: false,
    isSpecsAccordionOpen: true,
    userToggledSpecsAccordion: false,
    isRatingsAndReviewsAccordionOpen: false,
    isQuestionsAndAnswersAccordionOpen: false,
    isVideosAndArticlesAccordionOpen: false,
    loadingFreeShippingAndAvailability: true,
    loadingShippingEstimate: true,
    loadingIndexOptionSelection: false,
    aPlusContentScrollToLock: { active: false },
    readyForScrollData: { Details: false }
  });
  const [shippingInView, setShippingInView] = useState(false);
  const webUserGuid = useUserStore((x) => x.context.userGuid);
  const userVin = useUserStore((x) => x.context.userVin);
  const isBot = useUserStore((x) => x.context.isBot);

  const navigationContext = useContext(NavigationContext);
  const { setMisoId } = useContext(TrackingContext);
  const { sendSegmentTrackEventWithName } = useSegment();

  useEffect(() => {
    navigationContext.setPageType("PDP");
    // log out the time when the page was cached in vercel
    console.log('product service cached time: ', context.cachedTime)
    console.log('vercel html cached time: ', context.edgeCacheDate)
    useRecentHistory.AddToRecentHistory({ skuBaseNumber: context.skuBaseNumber });
    
  }, []);

  const bustStaticPage = (sendFacebookEvent) => {
    const productPageId = context.productPageId;
    const skuVariantNumber = context.skuVariantNumber;

    import("./fetches/product.js").then((f) => {
      f.bustStaticPage(productPageId, skuVariantNumber, webUserGuid).then(
        (data) => {
          setContext(x => {
            return {
              ...x,
              ...data,
            }
          });

          sendSegmentProductViewed(data);
          trackGoogleRemarketing(data);

          if (!navigator.globalPrivacyControl) {
            fbTracking(sendFacebookEvent);
          }
        }
      );
    });
  };

  const sendSegmentProductViewed = (data) => {
      try{
        if (!navigator.globalPrivacyControl && data.partNumber != null) {

          //log rocket sessions are tied to this log, don't remove without updating LR first
          console.info("sending Segment Product Viewed event");
          const {list_id, list_name, attribution} = useRecentHistory.GetMostRecentProductListObject()

          let misoId
          try{
            const misoIdData = useRecentHistory.GetAndDeleteMisoId();
            if(misoIdData && misoIdData.skuBase === data.skuBaseNumber){
              misoId = misoIdData.misoId
              setMisoId(misoId)
            }
          }
          catch(err){
            console.error(err)
          }

          const getSuperMarketAffinity = (item) => {
            item.superMarketAffinity = item.superMarketAffinity ?? [];
            if (item.superMarketAffinity.length === 0) {
              return "none";
            }
            if (item.superMarketAffinity.length === 1) {
              return item.superMarketAffinity[0].toLowerCase()
            }
            return "multiple"
          }

          let acquisition

          if(!attribution?.acquisition){
            useAcquisitionType((acquisitionType) => {acquisition = acquisitionType})
          }

          let segmentEvent = {
            sku: data.skuBaseNumber,
            variant: data.skuVariantNumber ? data.skuVariantNumber : null, // this will be undefined for sku base pages
            product_id: data.skuBaseNumber,
            name: data.title,
            brand: data.brand?.brandName,
            price: data.retailLowPrice,
            list_id: list_id,
            list_name: list_name,
            quantity: 1, // for viewing a product, qty of 1 seems a good default
            url: data.productPageUrl,
            image_url: (data.images || []).length ? `https:${data.images[0]}` : "",
            category: data.segmentCategorization,
            availability: data.availabilityEnum,
            rating: data.reviewCount ? data.rating : 0,
            reviews: data.reviewCount,
            audience_affinity: getSuperMarketAffinity(data),
            value: data.retailLowPrice, // same as price for qty = 1 // do we still want/need this?
            ...(attribution || {acquisition})
          }

          if(misoId) {
            segmentEvent.miso = { miso_id: misoId }
          }

          sendSegmentTrackEventWithName("Product Viewed", segmentEvent);
        }
    }
    catch(err){
      console.error('There was an error sending the segment product viewed event', err)
    }
  };

  const fbTracking = (sendFacebookEvent) => {
    if (typeof context.skuVariantNumber === "undefined") {
      return;
    }

    sendFacebookEvent({
      type: "track",
      name: "ViewContent",
      params: {
        content_name: context.title,
        content_ids: [context.skuVariantNumber],
        content_type: "product",
      },
    });
  };

  const trackGoogleRemarketing = (data) => {

    if (window.gtag) {
      window.gtag('event', 'view_item', {
        value: data?.retailLowPrice > 0 ? data?.retailLowPrice : context.retailLowPrice,
        items: [{
          id: data?.partNumber ? data.reportingSkuVariantNumber : context.reportingSkuVariantNumber,
          google_business_vertical: 'retail'
        }],
        send_to: "ads",
      })
    }

    // if (window.google_trackConversion) {
    //   var params = {
    //     ecomm_pagetype: "product",
    //     ecomm_prodid: [context.partNumber],
    //     ecomm_totalvalue: context.retailLowPrice,
    //   };

    //   window.google_trackConversion({
    //     google_conversion_id: "1061040585", // todo: need to stash this 'somewhere'
    //     google_custom_params: params,
    //     google_remarketing_only: true,
    //   });
    // }
  };

  const errorIndexOptions = () => {
    setClientContext({
      ...clientContext,
      isIndexOptionErrorState: true,
    });
    if (document.getElementById("pdp_offer")) {
      document
        .getElementById("pdp_offer")
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const updatePriceWithPriceBreak = (quantityRequested) => {
    try {
      if ((context.quantityBreaks || []).length) {
        let priceWithoutBreak = typeof context.originalPrice === 'string' && context.originalPrice.length ? context.originalPrice : context.price
        let savingsPriceWithoutBreak = typeof context.originalSavingsPrice === 'string' && context.originalSavingsPrice.length ? context.originalSavingsPrice : context.savingsPrice

        let newPrice = priceWithoutBreak
        let newSavingsPrice = savingsPriceWithoutBreak

        let quantityBreak = context.quantityBreaks.findLast((x) => quantityRequested >= x.priceLevelQuantity)
        if (typeof quantityBreak !== 'undefined' && quantityBreak !== null
          && quantityBreak.price !== null && quantityBreak.price !== '')
        {
          newSavingsPrice = quantityBreak.savingsPrice
          newPrice = quantityBreak.price
        }
        setContext({
          ...context,
          originalPrice: priceWithoutBreak,
          originalSavingsPrice: savingsPriceWithoutBreak,
          price: newPrice,
          savingsPrice: newSavingsPrice,
          needToCalculateQuantityBreak: false
        })
      } else {
        setContext({
          ...context,
          needToCalculateQuantityBreak: false
        })
      }
    }
    catch (error) {
      console.error("Failed to update price")
    }
  }

  useEffect(() => {
    if (context.needToCalculateQuantityBreak) {
      updatePriceWithPriceBreak(clientContext.quantityRequested)
    }
  }, [context.needToCalculateQuantityBreak])

  const updateQuantityRequested = (q) => {
    updatePriceWithPriceBreak(q)

    setClientContext({
      ...clientContext,
      quantityRequested: parseInt(q),
    });
  };

  const selectIndexOption = (
    productPageId,
    skuVariant,
    selectedIndexOptions,
    mostRecentlySelectedIndexOption,
    sendFacebookEvent
  ) => {

    // here's how to do automatic code splitting in contexts... we don't want huge contexts!!!
    import("./fetches/product.js").then((f) => {
      setClientContext({
        ...clientContext,
        loadingIndexOptionSelection: true,
      });
      f.onIndexOptionSelection(
        productPageId,
        skuVariant,
        selectedIndexOptions,
        mostRecentlySelectedIndexOption,
        webUserGuid
      ).then((data) => {

        setClientContext({
          ...clientContext,
          loadingIndexOptionSelection: false,
        });
        setContext(x => {
          return {
            ...x,
            ...data,
            originalPrice: 0,
            originalSavingsPrice: 0,
            needToCalculateQuantityBreak: true
          }
        });

        if (data.productPageUrl) {
          window.history.replaceState(null, '', `${data.productPageUrl}`)
        }

        useRecentHistory.AddToRecentHistory({ skuBaseNumber: data.skuBaseNumber, url: data.productPageUrl, title: data.title, brand: data.brand?.brandName, segmentCategorization: data.segmentCategorization });
        sendSegmentProductViewed(data);
        trackGoogleRemarketing(data);

        fbTracking(sendFacebookEvent);

        if (shippingInView) {
          getFreeShippingAndAvailability(data.skuVariantNumber);
        }

        getLastPurchasedDateForSku(webUserGuid, data.skuVariantNumber)
      });
    });
  };

  const getFreeShippingAndAvailability = (skuVariant) => {
    const productPageId = context.productPageId;
    // here's how to do automatic code splitting in contexts... we don't want huge contexts!!!
    import("./fetches/product.js").then((f) => {
      f.loadFreeShippingAndAvailability(productPageId, skuVariant).then(
        (data) => {
          if (data && data.freeShippingAndAvailability) {
            setShippingContext({
              ...shippingContext,
              loadingFreeShippingAndAvailability: false,
              freeShippingAndAvailability: data.freeShippingAndAvailability,
            });
          }
        }
      );
    });
  };

  const getLastPurchasedDateForSku = (webUserGuid, skuVariant) => {
    import("./fetches/checkout.js").then((f) => {
      f.getPurchasedDateForSku(webUserGuid, skuVariant).then(
        (data) => {
          setPurchasedOrderContext({
            ...purchasedOrderContext,
            lastPurchaseDateForSku: data && data.purchasedDateForSku ? data.purchasedDateForSku.orderedDate : null,
          });
        }
      );
    });
  };

  const getCustomerImagesForProduct = (skuBase) => {
    import("./fetches/product.js").then((f) => {
      f.GetCustomerImagesForProduct(skuBase).then(
        (data) => {
          setContext({
            ...context,
            customerImageUrls: data.imageUrls
          });
        }
      );
    });
  };

  const checkFitment = (checkFitmentRequest) => {
    const skuBaseNumber = context.skuBaseNumber;
    const skuVariantNumber = context.skuVariantNumber;

    import("./fetches/product.js").then((f) => {
      f.checkFitmentNew(
        skuBaseNumber,
        skuVariantNumber,
        checkFitmentRequest
      ).then((data) => {
        if (data && data.checkFitment) {
          console.log('data', data)
          setFitmentContext({
            ...fitmentContext,
            ...data.checkFitment,
          });
        }
      });
    });
  };

  const getShippingEstimate = (skuVariantNumber, postalCode) => {
    if (isBot()) {
      setClientContext({
        ...clientContext,
        loadingShippingEstimate: false,
      });
      return;
    }

    import("./fetches/checkout.js").then((f) => {
      setClientContext({
        ...clientContext,
        loadingShippingEstimate: true,
      });
      f.getShippingEstimate(
        skuVariantNumber,
        postalCode,
        userVin,
        webUserGuid
      ).then((data) => {
        if (data && data.shippingEstimate) {
          setShippingEstimateContext({
            ...shippingEstimateContext,
            shippingEstimate: {
              postalCode: postalCode,
              ...data.shippingEstimate,
            },
          });
        }
        setClientContext({
          ...clientContext,
          loadingShippingEstimate: false,
        });
      });
    });
  };

  const showAdditionalFacetDrawer = () => {
    setContext(x => {
      return {
        ...x,
        isAdditionalFacetDrawerOpen: true,
      }
    });
  };

  const hideAdditionalFacetDrawer = () => {
    setContext(x => {
      return {
        ...x,
        isAdditionalFacetDrawerOpen: false,
      }
    });
  };

  const showFitmentDrawer = () => {
    setContext(x => {
      return {
        ...x,
        isFitmentDrawerOpen: true,
      }
    });
  };

  const hideFitmentDrawer = () => {
    setContext(x => {
      return {
        ...x,
        isFitmentDrawerOpen: false,
      }
    });
  };

  const showGarageSaleDrawer = () => {
    setContext(x => {
      return {
        ...x,
        isGarageSaleDrawerOpen: true,
      }
    });
  };

  const hideGarageSaleDrawer = () => {
    setContext(x => {
      return {
        ...x,
        isGarageSaleDrawerOpen: false,
      }
    });
  };

  const showWhatsInTheKitDrawer = () => {
    setContext(x => {
      return {
        ...x,
        isWhatsInTheKitDrawerOpen: true,
      }
    });
  };

  const hideWhatsInTheKitDrawer = () => {
    setContext(x => {
      return {
        ...x,
        isWhatsInTheKitDrawerOpen: false,
      }
    });
  };

  const showInStorePickupDrawer = () => {
    setContext(x => {
      return {
        ...x,
        isInStorePickupDrawerOpen: true,
      }
    });
  };

  const hideInStorePickupDrawer = () => {
    setContext(x => {
      return {
        ...x,
        isInStorePickupDrawerOpen: false,
      }
    });
  };

  // 4. Tell CLS causing elements they can render now and clean up context
  // If you quickly setClientContext too many times it causes the whole context to reset
  // So we need this useEffect to make it happen exactly once... 
  // Scroll end events are so rapid and overlapped that it's necessary
  const [scrollStopped, setScrollStopped] = useState(false)
  useEffect(() => {
    if (scrollStopped) {
      setClientContext((prevClientContext) => {
        const { shouldOpenAccordion, ...rest } = prevClientContext;
        return {
          ...rest,
          [`is${prevClientContext.aPlusContentScrollToLock.accordionName}AccordionOpen`]: shouldOpenAccordion,
          aPlusContentScrollToLock: { active: false },
        };
      });
    }
  }, [scrollStopped]);

  // 3. Detect when scrolling is done
  const ScrollEnd = useCallback(() => {

    let scrollTimeout;

    clearTimeout(scrollTimeout);
    setTimeout(function () {
      setScrollStopped(true)
      window.removeEventListener('scroll', ScrollEnd)
    }, 1000)
  }, [])

  // 2. Start scrolling once we're sure CLS causing renders won't happen
  useEffect(() => {
    if (clientContext.aPlusContentScrollToLock?.active 
      && (!clientContext.aPlusContentScrollToLock.waitForAccordionOpen || (clientContext.readyForScrollData[clientContext.aPlusContentScrollToLock.accordionName]))) {
      document.getElementById(clientContext.aPlusContentScrollToLock.id).scrollIntoView({ behavior: 'smooth' })
      setScrollStopped(false)
      window.addEventListener('scroll', ScrollEnd);
    }
  }, [clientContext.aPlusContentScrollToLock, clientContext.readyForScrollData, clientContext.isDetailsAccordionOpen])

  // 1. Set context values to initiate scrolling without CLS
  const scrollTo = (id, accordionName = '', elementInAccordion = false, shouldOpenAccordion = true) => {
    setClientContext({
      ...clientContext,
      aPlusContentScrollToLock: {
        active: true,
        id: id,
        accordionName: accordionName,
        waitForAccordionOpen: elementInAccordion
      },
      shouldOpenAccordion: shouldOpenAccordion,
    });
  }

  const setIsReadyForScrollData = (state, accordionName) => {
    console.info('updating', state, accordionName)
    setClientContext({
      ...clientContext,
      readyForScrollData: {
        ...clientContext.readyForScrollData,
        [accordionName]: state
      }
    });
  }

  const toggleAccordion = (accordion, isOpen) => {
    setClientContext({
      ...clientContext,
      [`is${accordion}AccordionOpen`]: isOpen,
    });
  };

  const setIsCompareToolPresent = (value) => {
    setContext(x => {
      return {
        ...x,
        isCompareToolPresent: value,
      }
    });
  }

  return (
    <ProductPageContext.Provider
      value={{
        bustStaticPage,
        selectIndexOption,
        getFreeShippingAndAvailability,
        errorIndexOptions,
        updateQuantityRequested,
        checkFitment,
        getShippingEstimate,
        showFitmentDrawer,
        hideFitmentDrawer,
        showAdditionalFacetDrawer,
        hideAdditionalFacetDrawer,
        showGarageSaleDrawer,
        hideGarageSaleDrawer,
        showWhatsInTheKitDrawer,
        hideWhatsInTheKitDrawer,
        showInStorePickupDrawer,
        hideInStorePickupDrawer,
        setShippingInView,
        toggleAccordion,
        scrollTo,
        setIsReadyForScrollData,
        getLastPurchasedDateForSku,
        setIsCompareToolPresent,
        getCustomerImagesForProduct,
        ...context, // this might have a hidden cost to it
        ...fitmentContext,
        ...clientContext,
        ...shippingContext,
        ...shippingEstimateContext,
        ...purchasedOrderContext
      }}
    >
      <RecommendationProvider
        sections={[
          "PDP_Top",
          "PDP_Aside",
          "PDP_Bottom",
          "PDP_Kits",
          "PDP_Articles"
        ]}
        keywords={context.title}
        skuBaseNumbers={[context.skuBaseNumber]}
        skuVariantNumbers={context.skuVariantNumber ? [context.skuVariantNumber] : undefined}
      >
        {children}
      </RecommendationProvider>
    </ProductPageContext.Provider>
  );
};

export { ProductPageContext, ProductPageProvider };
