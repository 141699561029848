import React, { useContext } from "react";
import { ProductJsonLd, BreadcrumbJsonLd } from "next-seo";
import { ProductPageContext } from "./../../contexts/ProductPageContext";

const JsonLD = ({}) => {
  const productPageContext = useContext(ProductPageContext);

  //if there's not a sku variant number and there's not a price range, the json ld will always be invalid and cause errors in Google Search Console
  if (
    !productPageContext.skuVariantNumber &&
    !productPageContext.retailLowPrice
  )
    return false;

  const description = productPageContext.strippedInfoTab
    ? JSON.stringify(productPageContext.strippedInfoTab)
    : "";

  const disambiguatingDescription = productPageContext.metaDescription
    ? JSON.stringify(productPageContext.metaDescription)
    : "";

  const productName = productPageContext.title
    ? JSON.stringify(productPageContext.title)
    : "";
  return (
    <>
      <ProductJsonLd
        keyOverride={"product"}
        productName={productName.slice(1, productName.length - 1)}
        images={
          productPageContext.images && productPageContext.images[0]
            ? `https:${productPageContext.images[0]}`
            : ""
        }
        description={description.slice(1, description.length - 1)}
        disambiguatingDescription={disambiguatingDescription.slice(
          1,
          disambiguatingDescription.length - 1
        )}
        brand={
          productPageContext.brand && productPageContext.brand.brandName
            ? productPageContext.brand.brandName
            : ""
        }
        aggregateRating={
          productPageContext.rating > 0
            ? {
                ratingValue: productPageContext.rating,
                reviewCount: productPageContext.reviewCount,
              }
            : null
        }
        manufacturerName={
          productPageContext.brand && productPageContext.brand.brandName
            ? productPageContext.brand.brandName
            : ""
        }
        manufacturerLogo={
          productPageContext.brand && productPageContext.brand.brandImageUrl
            ? `https:${productPageContext.brand.brandImageUrl}`
            : ""
        }
        mpn={productPageContext.manufacturerPartNumber}
        sku={
          productPageContext.skuVariantNumber
            ? productPageContext.skuVariantNumber
            : productPageContext.skuBaseNumber
        }
        gtin8={
          productPageContext.gtin && productPageContext.gtin.length == 8
            ? productPageContext.gtin
            : null
        }
        gtin13={
          productPageContext.gtin && productPageContext.gtin.length == 13
            ? productPageContext.gtin
            : null
        }
        gtin14={
          productPageContext.gtin && productPageContext.gtin.length == 14
            ? productPageContext.gtin
            : null
        }
        //use 'offers' on a sku variant page, and 'aggregateOffer' on base pages
        offers={
          productPageContext.skuVariantNumber
            ? [
                {
                  price: productPageContext.hasPriceRange
                    ? ""
                    : productPageContext.retailLowPrice,
                  priceCurrency: "USD",
                  itemCondition: productPageContext.isGarageSale
                    ? "https://schema.org/DamagedCondition"
                    : "https://schema.org/NewCondition",
                  availability: productPageContext.microDataAvailability,
                  url: productPageContext.productPageUrl,
                  seller: {
                    name: "Speedway Motors",
                  },
                },
              ]
            : null
        }
        aggregateOffer={
          productPageContext.skuVariantNumber
            ? null
            : {
                lowPrice: productPageContext.retailLowPrice,
                highPrice: productPageContext.hasPriceRange
                  ? productPageContext.retailHighPrice
                  : productPageContext.retailLowPrice,
                priceCurrency: "USD",
                offerCount: (
                  (productPageContext.indexOptions || []).map(
                    (x) => x.indexOptionValues.length
                  ) || [1]
                ).length
                  ? (
                      productPageContext.indexOptions.map(
                        (x) => x.indexOptionValues.length
                      ) || [1]
                    ).reduce((a, b) => a * b)
                  : 1,
              }
        }
      />

      <BreadcrumbJsonLd
        keyOverride={"breadcrumb"}
        itemListElements={(productPageContext.breadcrumb || []).map((crumb) => {
          return {
            position: crumb.ordinality + 1,
            name: crumb.displayText,
            item: crumb.url
              ? `https://www.speedwaymotors.com${crumb.url}`
              : productPageContext.canonicalUrl,
          };
        })}
      />
    </>
  );
};

export default JsonLD;
