import React, { useContext, useRef } from 'react'

import Breadcrumb from 'Clutch/Molecules/Breadcrumb/Breadcrumb'
import { ProductPageContext } from './../../contexts/ProductPageContext'

const BreadcrumbWrapper = ({ }) => {
  const renderCounter = useRef(0);
  renderCounter.current = renderCounter.current + 1
  console.debug(`Breadcrumb Component Renders: ${renderCounter.current}`)

  const productPageContext = useContext(ProductPageContext)

  if((productPageContext.breadcrumb || []).length === 0)
    return

  const breadcrumb = productPageContext.breadcrumb.filter(crumb => crumb)

  if((breadcrumb || []).length === 0)
    return

  return (
    <Breadcrumb breadcrumb={breadcrumb} />
  )
}

export default BreadcrumbWrapper