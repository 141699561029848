import React, { useContext } from "react";
import { ProductPageContext } from "../../contexts/ProductPageContext"
import { AddToCartExperienceContext } from "Clutch/Contexts/AddToCartExperienceContext/AddToCartExperienceContext";
import Svg from "Clutch/Atoms/Svg";
import TextLink from "Clutch/Atoms/TextLink";
import Card from "Clutch/Atoms/Card"
import FeatureFlagWrapper from 'Clutch/UtilityComponents/FeatureFlagWrapper'

import styles from './addToList.module.scss'

const AddToList = ({className, dataTestId}) => {
  const productPageContext = useContext(ProductPageContext);
  const addToCartExperienceContext = useContext(AddToCartExperienceContext);

  const itemToAddToList = {
    skuVariantNumber: productPageContext.skuVariantNumber,
    skuBaseNumber: productPageContext.skuBaseNumber,
    quantityRequested: parseInt(productPageContext.quantityRequested),
    title: productPageContext.title,
    images: productPageContext.images,
    segmentCategory: productPageContext.segmentCategorization,
    brand: productPageContext.brand?.brandName,
    price: productPageContext.price,
    url: productPageContext.productPageUrl
  };

  return (
    <FeatureFlagWrapper ff={"Add_To_List_Badge_Expanded_PDP"}>
    <div className={className}>
      <Card
        className={styles.pdp_atl_background}
        layer={0}
        noPadding
        data-testid={dataTestId ?? "pdp-add-to-list"}
        onClick={(e) => {
          e.preventDefault();
          productPageContext.isIndexOptionKit
            ? addToCartExperienceContext.showKitDetailsDrawer({ list: true })
            : productPageContext.skuVariantNumber === ""
            ? productPageContext.errorIndexOptions()
            : addToCartExperienceContext.showListDrawer([itemToAddToList]);
        }}
      >
        <Svg
          size={1.25}
          tone={"subtle"}
          icon={"heart"}
          className={styles.pdp_atc_aid_icon}
        />
      </Card>
      </div>
    </FeatureFlagWrapper>
  );
};

export default AddToList