"use client"

import React from "react";
import dynamic from "next/dynamic";
import classNames from "classnames";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import { useInView } from "react-intersection-observer";
import {useEffect} from 'react'

import Panel from "Clutch/Atoms/Panel";
import Gutters from "Clutch/Atoms/Gutters";
import StickyHeader from "Clutch/Components/StickyHeader";
import BreadcrumbWrapper from "../../../../../containers/breadcrumb/breadcrumb";
import AddToList from "../../../../../containers/addToList/addToList";
import { useLazyFeatureFlag } from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag';

import HeadContent from "../../../../../containers/head/head";
import JsonLD from "../../../../../containers/json-ld/jsonLD";


//#region 
const Gallery = dynamic(() =>
  import("../../../../../chunks/chunk1").then((module) => module.Gallery)
);
const Offer = dynamic(() =>
  import("../../../../../chunks/chunk1").then((module) => module.Offer)
);

const Summary = dynamic(() =>
  import("../../../../../chunks/chunk1").then((module) => module.Summary)
);
const Details = dynamic(() =>
  import("../../../../../chunks/chunk1").then((module) => module.Details)
);
const Specs = dynamic(() =>
  import("../../../../../chunks/chunk1").then((module) => module.Specs)
);

const Contact = dynamic(() =>
  import("../../../../../chunks/chunk1").then((module) => module.Contact)
);
const InstallationDetails = dynamic(() =>
  import("../../../../../chunks/chunk1").then(
    (module) => module.InstallationDetails
  )
);

const AplusCardContent = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.AplusCardContent
    ),
  { ssr: false }
);
const Ratings = dynamic(
  () => import("../../../../../chunks/chunk1").then((module) => module.Ratings)
);
const QuestionsAndAnswers = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.QuestionsAndAnswers
    ),
  { ssr: false }
);
const VideosAndArticles = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.VideosAndArticles
    ),
  { ssr: false }
);
const ProductCompare = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.ProductCompare
    ),
  { ssr: false }
);

const StickyHeaderContent = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.StickyHeaderContent
    ),
  { ssr: false }
);
const MobileStickyAtc = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.MobileStickyAtc
    ),
  { ssr: false }
);

const BottomRecommendations = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.BottomRecommendations
    ),
  { ssr: false }
);
const SummaryRecommendations = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.SummaryRecommendations
    ),
  { ssr: false }
);
const AsideRecommendations = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.AsideRecommendations
    ),
  { ssr: false }
);
const KitRecommendations = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.KitRecommendations
    ),
  { ssr: false }
);
const AlternateRecommendations = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.AlternateRecommendations
    ),
  { ssr: false }
);
const SpeedwayAlternateRecommendations = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.SpeedwayAlternateRecommendations
    ),
  { ssr: false }
);

//#endregion

import GetStaticPaths from "../../../../../lib/getStaticPaths";
import GetStaticProps from "../../../../../lib/getStaticProps";

import styles from "./product.module.scss";

import { ProductPageProvider } from "../../../../../contexts/ProductPageContext";
import AdditionalFacetDrawer from "../../../../../containers/drawers/additionalFacetDrawer/additionalFacetDrawer";
//import EngineFitmentFeedbackWrapper from "../../../../../containers/engineFitmentFeedback/engineFitmentFeedbackWrapper";

const InStorePickupDrawer = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.InStorePickupDrawer
    ),
  { ssr: false }
);
const WhatsInTheKitDrawer = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.WhatsInTheKitDrawer
    ),
  { ssr: false }
);
const FitmentDrawer = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.FitmentDrawer
    ),
  { ssr: false }
);
const GarageSaleDrawer = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.GarageSaleDrawer
    ),
  { ssr: false }
);
const EngineFitmentFeedback = dynamic(
  () =>
    import("../../../../../chunks/chunk1").then(
      (module) => module.EngineFitmentFeedbackWrapper
    ),
  { ssr: false }
);


export async function getStaticPaths() {
  return GetStaticPaths();
}

export async function getStaticProps({ params }) {
  return await GetStaticProps(params);
}

export default function ProductPage(props) {
  const contextObject = {
    ...props,
  };

  console.log({props})

  //112px = 7rem, offset the sticky header itself
  const [summaryRef, summaryInView] = useInView({ rootMargin: "0px", threshold: 0.1 });
  const [summaryRecommendationsRef, summaryRecommendationsInView] = useInView({
    rootMargin: "-112px 0px",
  });
  const [aplusCardContentRef, aplusCardContentInView] = useInView({
    rootMargin: "-112px 0px",
  });
  const [detailsClosedRef, detailsClosedInView] = useInView({
    rootMargin: "-112px 0px",
  });
  const [detailsOpenRef, detailsOpenInView] = useInView({
    rootMargin: "-112px 0px",
  });
  const [guidesDocsRef, guidesDocsInView] = useInView({
    rootMargin: "-112px 0px",
  });
  const [carbProp65Ref, carbProp65InView] = useInView({
    rootMargin: "-112px 0px",
  });
  const [specsRef, specsInView] = useInView({ rootMargin: "-112px 0px" });
  const [ratingsRef, ratingsInView] = useInView({ rootMargin: "-112px 0px" });
  const [qnaRef, qnaInView] = useInView({ rootMargin: "-112px 0px" });
  const [videosRef, videosInView] = useInView({ rootMargin: "-112px 0px" });
  const [compareRef, compareRefInView] = useInView({
    rootMargin: "-112px 0px",
  });

  const inViewData = {
    summaryInView: summaryInView,
    summaryRecommendationsInView: summaryRecommendationsInView,
    aplusCardContentInView: aplusCardContentInView,
    detailsClosedInView: detailsClosedInView,
    detailsOpenInView: detailsOpenInView,
    guidesDocsInView: guidesDocsInView,
    carbProp65InView: carbProp65InView,
    specsInView: specsInView,
    ratingsInView: ratingsInView,
    qnaInView: qnaInView,
    videosInView: videosInView,
    compareRefInView: compareRefInView,
  };

  const compareBoostEnabled = useLazyFeatureFlag(props.brand?.brandName && !props.brand?.isHouseBrand ? "PDP_Compare_Tool_Boosting" : "placeholderdontdelete")

  return (
    <ProductPageProvider value={contextObject}>
      <HeadContent />
      <JsonLD />

      <Panel className={styles.pdp_layout}>
        <main
          className={styles.pdp_main}
          data-productcachedate={contextObject.cachedTime}
          data-edgecachedate={contextObject.edgeCacheDate}
        >
          <header
            className={classNames(
              styles.pdp_layout_breadcrumb,
              styles.pdp_above_fold
            )}
            id={"pdp_breadcrumb"}
          >
            <Panel>
              <BreadcrumbWrapper />
            </Panel>
          </header>
          <div
            className={classNames(
              styles.pdp_layout_vert,
              styles.pdp_above_fold
            )}
          >
            <AddToList
              className={styles.pdp_add_to_list}
              dataTestId={"pdp_add_to_list_addtl_mobile_placement"}
            />
            <Gutters className={styles.pdp_offer_gallery_gutter}>
              <div className={styles.pdp_layout_main}>
                <section
                  aria-label={"Image Gallery"}
                  className={styles.pdp_layout_gallery}
                  id={"pdp_gallery"}
                >
                  <ErrorBoundary>
                    <Gallery />
                  </ErrorBoundary>
                </section>
                <div className={styles.pdp_layout_spacer} />
                <section
                  aria-label={"Offer"}
                  className={styles.pdp_layout_offer}
                  id={"pdp_offer"}
                >
                  <Offer />
                </section>
              </div>
            </Gutters>
          </div>
          <Panel
            className={classNames(
              styles.pdp_layout_vert,
              styles.pdp_layout_summary
            )}
            id={"pdp_summary"}
          >
            <Gutters>
              <section
                aria-label={"Summary"}
                className={styles.pdp_layout_main}
                ref={summaryRef}
              >
                <Summary />
              </section>
            </Gutters>
          </Panel>
          {compareBoostEnabled ? (
            <div ref={compareRef}>
            <Gutters >
              <section
                aria-label={"Product Compare"}
                className={styles.pdp_compare}
                id={"pdp_productcompare"}
              >
                <ProductCompare />
              </section>
            </Gutters>
            </div>
          ) : (
            <section
              aria-label={"Recommendations"}
              className={classNames(
                styles.pdp_layout_main,
                styles.pdp_layout_summary_recommendations
              )}
              id={"pdp_summary_recommendations"}
              ref={summaryRecommendationsRef}
            >
              <SummaryRecommendations keywords={contextObject.title} />
            </section>
          )}
          <Panel
            className={classNames(
              styles.pdp_layout_vert,
              styles.pdp_below_fold
            )}
          >
            <div
              className={styles.pdp_layout_vert}
              id={"pdp_card_aplus"}
              ref={aplusCardContentRef}
            >
              <AplusCardContent />
            </div>
            <Gutters>
              <aside className={styles.pdp_layout_aside} id={"pdp_aside"}>
                <InstallationDetails />
                <AsideRecommendations keywords={contextObject.title} />
                <AlternateRecommendations keywords={contextObject.title} />
                <SpeedwayAlternateRecommendations
                  keywords={contextObject.title}
                />
                <Contact />
                <KitRecommendations keywords={contextObject.title} />
              </aside>
              <section
                aria-label={"Details"}
                className={styles.pdp_layout_cards}
                id={"pdp_details"}
              >
                <Details
                  stickyHeaderRefs={{
                    detailsClosed: detailsClosedRef,
                    detailsOpen: detailsOpenRef,
                    guidesDocs: guidesDocsRef,
                    carbProp65: carbProp65Ref,
                  }}
                />
              </section>
              <section
                aria-label={"Specifications"}
                className={styles.pdp_layout_cards}
                id={"pdp_specs"}
              >
                <Specs stickyHeaderRef={specsRef} />
              </section>
              <section
                aria-label={"Ratings and Reviews"}
                className={styles.pdp_layout_cards}
                id={"pdp_ratings"}
              >
                <Ratings stickyHeaderRef={ratingsRef} />
              </section>
              <section
                aria-label={"Questions and Answers"}
                className={styles.pdp_layout_cards}
                id={"pdp_qna"}
              >
                <QuestionsAndAnswers stickyHeaderRef={qnaRef} />
              </section>
              <section
                aria-label={"Videos and Articles"}
                className={styles.pdp_layout_cards}
                id={"pdp_videosAndArticles"}
              >
                <VideosAndArticles stickyHeaderRef={videosRef} />
              </section>
            </Gutters>
          </Panel>
          {compareBoostEnabled ? (
            <section
              aria-label={"Recommendations"}
              className={classNames(
                styles.pdp_layout_main,
                styles.pdp_layout_summary_recommendations
              )}
              id={"pdp_summary_recommendations"}
              ref={summaryRecommendationsRef}
            >
              <SummaryRecommendations keywords={contextObject.title} />
            </section>
          ) : (
            <Gutters >
              <section
                aria-label={"Product Compare"}
                className={styles.pdp_compare}
                ref={compareRef}
                id={"pdp_productcompare"}
              >
                <ProductCompare />
              </section>
            </Gutters>
          )}
        </main>
        <BottomRecommendations />
      </Panel>

      <StickyHeader triggerId={"pdp-add-to-cart-button"}>
        <Gutters noVerticalPadding>
          <StickyHeaderContent inViewData={inViewData} />
        </Gutters>
      </StickyHeader>

      <MobileStickyAtc triggerId={"pdp_summary"} />
      <FitmentDrawer />
      <AdditionalFacetDrawer/>
      <GarageSaleDrawer />
      <InStorePickupDrawer />
      <WhatsInTheKitDrawer />
      <EngineFitmentFeedback />
    </ProductPageProvider>
  );
}
